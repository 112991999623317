<template>
  <div class="parcel">
    <div class="table_name">
      {{list.month}}月核心工作关键性绩效量化指标考核表
    </div>
    <div class="table_ys">
      <table>
        <tr>
          <td class="bm bag1">部门：</td>
          <td class="bm_text">{{list.departmentName}}</td>
          <td class="gw bag1">姓名：</td>
          <td class="gw_text">{{list.publishName}}</td>
          <td class="xim bag1">岗位：</td>
          <td class="xm_text">{{list.position}}</td>
        </tr>
      </table>
    </div>
    <div>
      <el-table
        :data="tableData"
        border
        show-summary
        style="width: 100%"
       >
        <el-table-column
          v-for="item in cols"
          :key="item.key"
          :prop="item?.prop??''"
          :label="item?.label??''"
          :width="item?.width??''"
        >
          <template #default="scope">
            <div @click="() =>{getScope(scope)}">{{ item.render?scope.$index + 1: (scope.row[scope.column.property])}}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="evaluate">
      员工自我评价
    </div>
    <div class="table_ys">
      <table>
        <tr>
          <td class="ys">优势：</td>
          <td class="nr">{{list.selfAssessAdvantage}}</td>
        </tr>
        <tr>
          <td class="ys">劣势：</td>
          <td class="nr">{{list.selfAssessImprovement}}</td>
        </tr>
      </table>
    </div>
    <div class="evaluate">
      主管上级评价
    </div>
    <div class="table_ys">
      <table>
        <tr>
          <td class="ys">优势：</td>
          <td class="nr">{{list.superiorReviewAdvantage}}</td>
        </tr>
        <tr>
          <td class="ys">劣势：</td>
          <td class="nr">{{list.superiorReviewImprovement}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="daoChu">
    <el-button type="primary" @click="derive">导出</el-button>
  </div>
</template>
<script>
import { onMounted, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { getInfo, orderAllDataExcel } from '@/api/user'
import { ElLoading, ElMessage } from 'element-plus'
import { useStore } from '@/vuex/store'
export default {
  name: 'summaryDrawer',
  props: {
    planId: String
  },
  setup (props) {
    const list = ref({})
    const tableData = ref([])
    const cols = ref([
      {
        prop: 'id',
        label: '目标',
        width: '60',
        render: true,
        key: '1'
      },
      {
        prop: 'corePerformanceIndicators',
        label: '核心工作指标',
        width: '270',
        key: '2'
      },
      {
        prop: 'standardEvaluation',
        label: '指标标准值',
        width: '250',
        key: '3'
      },
      {
        prop: 'indicatorsStandardValue',
        label: '评分标准值',
        width: '200',
        key: '4'
      },
      {
        prop: 'weight',
        label: '权重(配分100)',
        width: '80',
        key: '5'
      },
      {
        prop: 'departmentName',
        label: '数据来源部门',
        width: '135',
        key: '6'
      },
      {
        prop: 'completion',
        label: '完成情况',
        width: '130',
        key: '7'
      },
      {
        prop: 'evaluationScores',
        label: '得分',
        width: '110',
        key: '8'
      },
      {
        prop: 'score',
        label: '复核得分',
        width: '110',
        key: '9'
      }
    ])
    const getScope = (e) => {
      console.log(e)
    }
    const getList = () => {
      getInfo({
        planId: props.planId
      }).then(res => {
        if (res.code === 200) {
          list.value = res.data
          console.log(res.data)
          res.data.plan.forEach(item => {
            if (res.data.departmentName) {
              item.departmentName = res.data.departmentName
              item.score = 0
            } else {
              item.departmentName = ''
              item.score = 0
            }
          })
          if (res.data.score) {
            res.data.plan[0].score = res.data.score !== 'NaN' ? res.data.score : 0
          }
          console.log(res.data.plan)
          tableData.value = res.data.plan
        }
      })
    }
    // 导出
    const isExcel = (type, name, data) => {
      const link = document.createElement('a')
      const blob = new Blob([data])
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', `${name}.` + type)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    const derive = () => {
      orderAllDataExcel({
        month: list.value.month,
        userid: list.value.publishId
      }).then(res => {
        // isExcel('xlsx', '下载', res)
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
        const objectUrl = URL.createObjectURL(blob)
        console.log(objectUrl)
        window.location.href = objectUrl
        // if (res.code === 200) {
        //   ElMessage.success('导出成功')
        // } else {
        //   ElMessage.error('导出失败')
        // }
      })
    }

    onMounted(() => {
      if (props.planId) {
        getList()
      }
    })
    return {
      list,
      tableData,
      cols,
      derive,
      getScope
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.parcel{
  width: 95%;
  margin: 0 auto;
  opacity: 1;
  background: #ffffff;
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 5px;
  .table_name{
    width: 100%;
    height: 50px;
    text-align: center;
    opacity: 1;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0,0,0,0.85);
    line-height: 50px;
    margin: 0 auto;
  }
  .evaluate {
    height: 60px;
    line-height: 60px;
    opacity: 1;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: rgba(0,0,0,0.85);
    padding-left: 30px;
  }
  .table_ys{
    width: 100%;
    border: 1px solid #e8e8e8;
    text-align: center;
    .bm{
      width: 325px;
    }
    .bm_text{
      width: 245px;
      font-size: 14px;
    }
    .gw{
      width:196px;
    }
    .gw_text{
      width: 77px;
    }
    .xim{
      width: 135px;
    }
    .xm_text{
      width: 250px;
    }
    .ys{
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-weight: 600;
      text-align: center;
      font-size: 14px;
      border-right: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
    }
    .bag1 {
      background-color: #e6ebf0;
      height: 60px;
      line-height: 60px;
      font-weight: 600;
      font-size: 14px;
    }
    .nr{
      width: 1400px;
      opacity: 1;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      padding-left: 10px;
      color: rgba(0,0,0,0.65);
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .el-table th{
    background: #fafafa !important;
  }
}
.daoChu{
  width: 100%;
  text-align: center;
  height: 200px;
  line-height: 200px;
  .el-button {
    width: 80px;
    height: 40px;
  }
}
</style>

<template>
  <div class="headquarterList">
    <div class="headline">月绩效汇总表</div>
    <div class="search">
      <div class="block" style="margin-left: 41px">
        <el-date-picker
          v-model="year"
          type="year"
          @change="timing"
          placeholder="选择年"
        >
        </el-date-picker>
      </div>
      <div class="select_sh">
        <el-select v-model="departmentId" placeholder="请选择部门">
          <el-option
            v-for="item in optionsList"
            :key="item.id"
            :label="item.departmentName"
            :value="item.departmentId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="input_sh">
        <el-input placeholder="请输入姓名" v-model="name" clearable> </el-input>
      </div>
      <div class="button_cx">
        <el-button type="primary" @click="inquire">查询</el-button>
      </div>
      <div class="button_cz">
        <el-button type="primary" @click="reset" plain>重置</el-button>
      </div>
      <div class="button_dc">
        <el-button type="primary" @click="daoChu" :disabled="disable1">
          <i class="ico export"></i>
          <span>导出</span>
        </el-button>
      </div>
      <div class="button_qdc">
        <el-popover placement="top" :width="260" v-model:visible="visible">
          <div class="block" style="margin: 10px 0">
            <el-date-picker
              v-model="selectionTime"
              type="month"
              @change="timing2"
              placeholder="选择详情导出日期"
            >
            </el-date-picker>
          </div>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="cancel">取消</el-button>
            <el-button type="primary" size="mini" @click="daoChu1"
              >确定</el-button
            >
          </div>
          <template #reference>
            <el-button type="primary" @click="popupWindows" :disabled="disable"
              >详情导出</el-button
            >
          </template>
        </el-popover>
      </div>
    </div>
    <div class="tabe_class">
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 95%"
        @cell-click="
          (row, column, cell, event) => {
            // console.log(row, column, cell, event)
            //     event.preventDefault()
            cellClick(row, column, cell, event);
          }
        "
        @select="selectClick"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          v-for="item in col"
          :key="item.prop"
          :prop="item?.prop ?? ''"
          :label="item?.label ?? ''"
          :width="item?.width ?? ''"
          :fixed="item?.fixed"
        >
          <template #default="scope">
            <div>
              {{
                item.render
                  ? scope.row[scope.column.property]
                  : (scope.row[scope.column.property]?.split("_")[0] == "0.0"
                      ? null
                      : scope.row[scope.column.property]?.split("_")[0]) ?? "_"
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationY">
      <el-pagination
        :page-size="10"
        :pager-count="7"
        background
        layout="prev, pager, next"
        @current-change="currentChange"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <el-drawer title="详情" v-model="drawer" destroy-on-close>
        <summaryDrawer :planId="planId" />
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import summaryDrawer from '../drawer/summaryDrawer'
import { useRouter } from 'vue-router'
import { department, orderAllDataExcel2, performancePlan } from '@/api/user'
import { ElLoading, ElMessage } from 'element-plus'
import { useStore } from '@/vuex/store'
import moment from 'moment'
export default {
  name: 'summarySheet',
  setup () {
    const departmentId = ref('')
    const name = ref('')
    const multipleSelection = ref([])
    const total = ref('')
    const tableData = ref([])
    const optionsList = ref([])
    const exportList = ref([])
    const page = ref(1)
    const year = ref('')
    const drawer = ref(false)
    const planId = ref('')
    const selectionTime = ref('')
    const col = ref([
      {
        prop: 'id',
        label: '序号',
        width: 85,
        render: true,
        fixed: 'left'
      },
      {
        prop: 'departmentName',
        label: '部门',
        width: 230,
        render: true,
        fixed: 'left'
      },
      {
        prop: 'userName',
        label: '姓名',
        width: 125,
        render: true,
        fixed: 'left'
      },
      {
        prop: '01',
        label: '1月',
        width: 95
      },
      {
        prop: '02',
        label: '2月',
        width: 95
      },
      {
        prop: '03',
        label: '3月',
        width: 95
      },
      {
        prop: '04',
        label: '4月',
        width: 95
      },
      {
        prop: '05',
        label: '5月',
        width: 95
      },
      {
        prop: '06',
        label: '6月',
        width: 95
      },
      {
        prop: '07',
        label: '7月',
        width: 95
      },
      {
        prop: '08',
        label: '8月',
        width: 95
      },
      {
        prop: '09',
        label: '9月',
        width: 95
      },
      {
        prop: '10',
        label: '10月',
        width: 95
      },
      {
        prop: '11',
        label: '11月',
        width: 95
      },
      {
        prop: '12',
        label: '12月',
        width: 95
      }
    ])
    const start = ref(false)
    const disable = ref(false)
    const disable1 = ref(false)
    const visible = ref(false)
    //  获取页面数据
    const getPerformanceList = () => {
      performancePlan({
        currPage: page.value,
        pageSize: 10,
        year: year.value,
        departmentId: departmentId.value,
        userName: name.value
      }).then((res) => {
        console.log(res.data)
        if (res.code === 200) {
          total.value = res.data.totalCount
          let list = [...res.data.list]
          list = list.map((item) => {
            if (item.object) {
              item.object.forEach((element) => {
                if (element.month) {
                  const arr = element.month.split('-')
                  item[arr[1]] = element.score + '_' + element.planId
                }
              })
              return item
            }
          })
          tableData.value = list
          console.log(list)
        }
      })
    }
    // 获取部门列表
    const getDepartment = () => {
      department({}).then((res) => {
        if (res.code === 200) {
          optionsList.value = res.data
        }
      })
    }
    // 查询
    const inquire = () => {
      getPerformanceList()
    }
    // 重置
    const reset = () => {
      page.value = 1
      year.value = ''
      departmentId.value = ''
      name.value = ''
      getPerformanceList()
    }
    // 下一页
    const currentChange = (e) => {
      if (e) {
        page.value = e
      }
      getPerformanceList()
    }
    // 导出
    const daoChu = () => {
      disable1.value = true
      if (start.value === true) {
        console.log(2222222)
        derive()
      } else {
        performancePlan({
          currPage: page.value,
          pageSize: 1000,
          year: year.value,
          departmentId: departmentId.value,
          userName: name.value
        }).then((res) => {
          console.log(res.data)
          if (res.code === 200) {
            let eList = [...res.data.list]
            eList = eList.map((item) => {
              if (item.object) {
                item.object.forEach((element) => {
                  const e = element.month.split('-')
                  item[e[1]] = element.score
                })
                return item
              }
            })
            console.log(eList)
            exportList.value = eList
            derive()
          }
        })
      }
    }
    // 导出弹窗
    const popupWindows = () => {
      visible.value = true
    }
    // 关闭弹窗
    const cancel = () => {
      visible.value = false
      selectionTime.value = ''
    }
    // 导出详细
    const daoChu1 = () => {
      visible.value = false
      disable.value = true
      if (selectionTime.value) {
        orderAllDataExcel2({
          month: selectionTime.value
        }).then((res) => {
          // isExcel('xlsx', '下载', res)
          const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
          const objectUrl = URL.createObjectURL(blob)
          console.log(objectUrl)
          window.location.href = objectUrl
          selectionTime.value = ''
          disable.value = false
        })
      } else {
        ElMessage.error('请先选择日期')
        disable.value = false
      }
    }
    // 导出数据
    const derive = () => {
      console.log(exportList.value)
      require.ensure([], () => {
        const { exportJsonToExcel } = require('../../../excel/Export2Excel')
        const tHeader = [
          '序号',
          '部门',
          '姓名',
          '1月',
          '2月',
          '3月',
          '4月',
          '5月',
          '6月',
          '7月',
          '8月',
          '9月',
          '10月',
          '11月',
          '12月'
        ]
        const filterVal = [
          'id',
          'departmentName',
          'userName',
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12'
        ]
        const list = exportList.value
        const data = formatJson(filterVal, list)
        exportJsonToExcel(
          tHeader,
          data,
          '月绩效统计' + moment().format('YYYY-MM')
        )
      })
      disable1.value = false
    }
    const formatJson = (filterVal, jsonData) => {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    }
    // 处理时间
    const timing = (e) => {
      if (e) {
        const dateData = e
        const date = new Date(dateData)
        const y = date.getFullYear()
        const time = String(y)
        year.value = time
      }
    }
    const cellClick = (row, column, cell, event) => {
      console.log(row[column.property], event)
      if (row[column.property]) {
        const id = row[column.property].split('_')[1]
        if (typeof id === 'string' && id !== '') {
          drawer.value = true
          planId.value = id
        }
      } else {
        ElMessage.success('请点击评分查看详情')
      }
    }
    const timing2 = (e) => {
      if (e) {
        const dateData = e
        const date = new Date(dateData)
        const y = date.getFullYear()
        let m = date.getMonth() + 1
        m = m < 10 ? '0' + m : m
        const time = y + '-' + m
        selectionTime.value = time
      }
    }
    const selectClick = (selection, row) => {
      console.log(selection, row, 111)
      const arr1 = []
      selection.map((item) => {
        for (const key in item) {
          if (
            [
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12'
            ].includes(key)
          ) {
            arr1.push(key)
          }
        }
        arr1.forEach((e) => {
          console.log(item[e].split('_')[0])
          item[e] = item[e].split('_')[0]
        })
      })
      console.log(selection)
      start.value = true
      exportList.value = selection
    }
    onMounted(() => {
      getPerformanceList(10)
      getDepartment()
    })
    return {
      departmentId,
      optionsList,
      tableData,
      multipleSelection,
      name,
      selectClick,
      getPerformanceList,
      total,
      col,
      currentChange,
      page,
      cellClick,
      getDepartment,
      year,
      timing,
      timing2,
      drawer,
      planId,
      inquire,
      reset,
      derive,
      formatJson,
      exportList,
      daoChu,
      daoChu1,
      selectionTime,
      popupWindows,
      visible,
      cancel,
      disable,
      disable1
    }
  },
  components: {
    summaryDrawer
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.headquarterList {
  .headline {
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding-left: 200px;
  }
  .search {
    display: flex;
    .select_sh {
      width: 224px;
      margin-left: 40px;
    }
    .input_sh {
      width: 224px;
      margin-left: 12px;
    }
    .input3 {
      width: 224px;
      margin-left: 23px;
    }
    .button_cx {
      margin-left: 30px;
    }
    .button_cz {
      margin-left: 10px;
    }
    .button_dc {
      margin-left: 300px;
      .ico {
        width: 18px;
        height: 15px;
        display: inline-block;
        z-index: 10;
        background-repeat: no-repeat;
      }
    }
    .button_qdc {
      margin-left: 20px;
    }
  }
  .monthly .el-button--small {
    padding: 4px 4px !important;
  }
  .el-drawer__body {
    overflow: auto;
    height: 100%;
  }
  .el-drawer__open .el-drawer.rtl {
    width: 28% !important;
  }
  .el-input--small .el-input__inner {
    width: 100%;
    height: 40px;
    line-height: 32px;
  }
  .el-switch.is-disabled {
    opacity: 0.6;
    margin-left: 17px;
  }
  .el-drawer.rtl {
    width: 75% !important;
  }
  .el-button {
    width: 79px;
    font-size: 14px !important;
    border-right: 1px solid #d3d8dd;
  }
  .el-drawer__header {
    margin-bottom: 16px !important;
  }
  .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
    margin-right: 15px;
    font-size: 14px;
  }
  .el-table th {
    background-color: #e6ebf0;
    color: #000;
  }
  .el-pagination {
    margin-right: 62px;
    margin-top: 50px;
  }
  .el-switch {
    margin-left: 20px;
  }
  .el-table th,
  .el-table tr {
    font-size: 16px;
  }
  .el-table .cell {
    cursor: pointer;
  }
  .el-table--small td,
  .el-table--small th {
    padding: 15px 0;
    font-size: 16px;
  }
  .tabe_class {
    margin-left: 195px;
    margin-top: 20px;
  }
  .dian {
    margin-right: 8px;
    content: " ";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-top: 9px;
  }
  .green {
    background-color: #52c41a;
  }
  .red {
    background-color: red;
  }
  .inline {
    width: 60px;
    display: inline-block;
  }
  .paginationY {
    width: 100%;
    text-align: center;
    margin-right: 50px;
  }
}
</style>
